import React, { forwardRef } from "react";

const About = forwardRef((props, ref) => {
    return (
        <div className="container mx-auto">
            <div ref={ref} className="grid sm:grid-cols-1 lg:grid-cols-2 gap-8 items-center py-10">
                <div className='text-left px-12 text-white'>
                    <span className="block font-bold font-poppins text-6xl text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-pink-700">
                        About
                    </span>
                    <p className='pt-6 font-poppins'>
                        The MetaHouses collection consists of 10000 uniquely handcrafted 3D home NFTs built for the future habitants of the Metaverse. <br/> <br/>
                        Each individual MetaHouse has different characteristics ranging from a different theme, shape, size, color and many more to make each one unique and perfect to be the place you call home in the Metaverse. Moreover, items like pools and rockets make some even more rare and special. 
                        MetaHouses represent the vastness of the new universe unfolding before our eyes, the Metaverse.<br/> <br/>

                        Our unique features enable the creation of self-sufficient living spaces on the open world of the Metaverse, a fully immersive 3D experience.<br/> <br/>

                        It’s a small step in our journey, but one giant leap for the Metaversians. Are you ready to join the community? <br/> <br/>
                        See you in the Metaverse!
                    </p>
                </div>
                    <img className='p-6' src='temple2.png' />
            </div>
        </div>
    )
})

export default About;