import React, { forwardRef }  from 'react'
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

const Roadmap = forwardRef((props, ref) => {
  return (
    <div className="container mx-auto">
        <div ref={ref} className="items-center py-10">
            <span className="block font-bold font-poppins text-6xl text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-pink-700">
                Roadmap
            </span>
        </div>
        <VerticalTimeline className='font-poppins' layout='1-column-left' lineColor='rgb(50, 2, 200)'>
            <VerticalTimelineElement
                className="vertical-timeline-element--work"
                contentStyle={{ background: 'linear-gradient(90deg, rgba(66, 6, 145 ,1) 0%, rgba(150, 84, 140 ,1) 100%)', color: '#fff' }}
                contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
            >
                <div className='text-3xl text-black' >10000 MetaHouses auctioned on the 11th January</div>
            </VerticalTimelineElement>
            <VerticalTimelineElement
                className="vertical-timeline-element--work"
                contentStyle={{ background: 'linear-gradient(90deg, rgba(66, 6, 145 ,1) 0%, rgba(150, 84, 140 ,1) 100%)', color: '#fff' }}
                contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
            >
                <div className='text-3xl text-black' >3D printed models released</div>
            </VerticalTimelineElement>
            <VerticalTimelineElement
                className="vertical-timeline-element--work"
                contentStyle={{ background: 'linear-gradient(90deg, rgba(66, 6, 145 ,1) 0%, rgba(150, 84, 140 ,1) 100%)', color: '#fff' }}
                contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
            >
                <div className='text-3xl text-black' >Community Decision on collaboration with a metaverse</div>
            </VerticalTimelineElement>
            <VerticalTimelineElement
                className="vertical-timeline-element--work"
                contentStyle={{ background: 'linear-gradient(90deg, rgba(66, 6, 145 ,1) 0%, rgba(150, 84, 140 ,1) 100%)', color: '#fff' }}
                contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
            >
                <div className='text-3xl text-black'> Community events and meet-ups</div>
            </VerticalTimelineElement>
            <VerticalTimelineElement
                className="vertical-timeline-element--work"
                contentStyle={{ background: 'linear-gradient(90deg, rgba(66, 6, 145 ,1) 0%, rgba(150, 84, 140 ,1) 100%)', color: '#fff' }}
                contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
            >
                <div className='text-3xl text-black' >Community decision on MetaHouses 2.0</div>
            </VerticalTimelineElement>
            <VerticalTimelineElement
                className="vertical-timeline-element--work"
                contentStyle={{ background: 'linear-gradient(90deg, rgba(66, 6, 145 ,1) 0%, rgba(150, 84, 140 ,1) 100%)', color: '#fff' }}
                contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
            >
                <div className='text-3xl text-black' >MetaHouses 2.0 launches</div>
            </VerticalTimelineElement>
        </VerticalTimeline>
    </div>
  );
})

export default Roadmap