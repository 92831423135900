import React, {Component} from "react";
import {Navbar, Nav, Container } from 'react-bootstrap'

const icons = [
  {
    name: 'Twitter',
    href: 'https://twitter.com/metahouses',
    src: 'twitter.svg',
  },
  {
    name: 'Discord',
    href: 'https://discord.gg/wTFWSWW7Ht',
    src: 'discord.svg',
  },
]

export default function NavComp(props) {
  
  const items = [
    {
      name: 'About',
      ref: props.refs.about,
    },
    {
      name: 'Mint',
      ref: props.refs.mint,
    },
    {
      name: 'Roadmap',
      ref: props.refs.roadmap,
    },
    {
      name: 'Team',
      ref: props.refs.team,
    },
  ]
  return (
              <Navbar className='font-poppins h-15' variant="dark" expand="lg" fixed='top' style={{ position: "sticky", top: 0, backgroundColor: 'black'}}>
                <Container>
                    <Navbar.Brand href="/">
                        MetaHouses
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="ml-auto items-center p-0 bg-black">
                        {items.map((item) => (
                          <Nav.Link key={item.name} className='px-3 duration-200' onClick={()=>{props.scroll(item.ref)}}>
                            {item.name}
                          </Nav.Link>
                        ))}
                        {icons.map((icon) => (
                          <Nav.Link key={icon.name} className='px-3' href={icon.href}>
                            <img src={icon.src} style={{height: '1.7em'}}/>
                          </Nav.Link>
                        ))}
                    </Nav>
                    </Navbar.Collapse>
                </Container>
              </Navbar>
  )
}