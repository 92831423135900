import './App.css';
import Home from './Components/Home';
import About from './Components/About';
import { useEffect, useRef } from 'react';
import Aos from 'aos';
import "aos/dist/aos.css"
import Team from './Components/Team';
import Footer from './Components/Footer';
import Mint from './Components/Mint';
import NavComp from './Components/Navbar';
import Roadmap from './Components/Roadmap';
import FAQ from './Components/faq';

function App () {

  const refs = {
    home: useRef(null),
    about: useRef(null),
    mint: useRef(null),
    team: useRef(null),
    roadmap: useRef(null)
  }

  const onClick = (ref) => {
    ref.current.scrollIntoView({block: 'start', behavior: 'smooth'});
  }
  
  useEffect(() => {
    Aos.init({duration: 1000})
  }, []);
  if(window.location.pathname !== '/') {
    window.location.replace("/");
  }
  return (
      <div className="App text-black" style={{backgroundColor: '#000000'}}>
        <NavComp scroll={onClick} refs={refs}/>
        <div style={{backgroundImage: 'url("/snow3.png")', height: '100vh', backgroundSize: 'cover', backgroundPosition: 'center'}}>
          <Home ref={refs.home} scroll={onClick} refs={refs}/>
        </div>
        <div className='pt-2'>
          <About ref={refs.about}/>
        </div>
        <div className='pb-10'>
          <Mint ref={refs.mint}/>
        </div>
        <div>
          <Roadmap ref={refs.roadmap}/>
        </div>
        <div className='pt-10'>
          <FAQ ref={refs.team}/>
        </div>
        <div className='pt-10'>
          <Team ref={refs.team}/>
        </div>
        {/* <div>
          <Footer/>
        </div> */}
      </div>
    );
}

export default App;
