import React, { forwardRef } from "react";

const teamMembers = [
    {
       name: 'kxsar',
       twitter: 'https://twitter.com/kxsar_eth',
       image: 'kxsar.jpg', 
    },
    {
        name: 'flogi',
        twitter: 'https://twitter.com/flogi_eth',
        image: 'flogi.jpg', 
    },
    {
        name: 'styx',
        twitter: 'https://twitter.com/GLZfolded',
        image: 'styx.png', 
    },
    {
        name: 'bripubropu',
        twitter: 'https://twitter.com/BripuBropu',
        image: 'bripubropu.png', 
    },
]

const Team = forwardRef((props, ref) => {
    return (
        <div ref={ref} className="container mx-auto pt-24 pb-48">
            <span className="block font-bold font-poppins text-6xl text-transparent bg-clip-text bg-gradient-to-r from-blue-700 to-pink-300">
                Our Team
                <div className="block text-2xl text-white p-12">
                
                Our team is a young and enthusiastic group of developers, artists and marketers, working to create a virtual world in which players can interact with the MetaHouses NFT collection.
                
                </div>
            </span>
            <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 items-center pt-12">
                {teamMembers.map((member) => (
                    <div key={member.name} className='items-center justify-center flex text-white pt-12' style={{display: 'inline-block', textAlign: 'center'}}>
                        <img className='w-4/12 rounded-full' src={member.image} style={{ margin: '0 auto'}}/>
                        <div className='pt-6'>
                            <a className='font-bold font-poppins text-2xl hover:text-gray-500 duration-200' style={{textDecoration: 'none'}} href={member.twitter}>{member.name}</a>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
})

export default Team;