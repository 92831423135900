import React, { forwardRef }  from 'react'
import Faq from "react-faq-component";

const data = {
  rows: [
      {
          title: "When is the presale?",
          content: <p className='text-left'>The presale will be on the 10th January.</p>,
      },
      {
          title: "When is the public sale?",
          content: <p className='text-left'>The public sale will begin on the 11th January.</p>,
      },
      {
          title: "How do I get whitelisted for the presale?",
          content: <p className='text-left'>There are 200 whitelist spots. Join our discord server for more info!</p>,
      },
      {
          title: "What is the mint price?",
          content: <p className='text-left'>Public sale mint price will be 0.1 ETH, and presale will be 0.06 ETH</p>,
      },
  ],
  styles: {
    // bgColor: 'white',
    titleTextColor: 'blue',
    // titleTextSize: '48px',
    rowTitleColor: 'blue',
    // rowTitleTextSize: 'medium',
    // rowContentColor: 'grey',
    rowContentTextSize: '16px',
    // rowContentPaddingTop: '10px',
    rowContentPaddingBottom: '50px',
    // rowContentPaddingRight: '150px',
    // arrowColor: "red",
    //transitionDuration: "1s",
    // timingFunc: "ease"
  }
};

const styles = {
  bgColor: 'black',
  titleTextColor: "white",
  rowTitleColor: "white",
  rowContentColor: 'white',
  arrowColor: "white",
};

const config = {
  animate: true,
  arrowIcon: "v",
  // tabFocus: true
};

const FAQ = forwardRef((props, ref) => {
  return (
    <div className="container mx-auto">
        <div ref={ref} className="items-center py-10">
            <span className="block font-bold font-poppins text-6xl text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-pink-700">
                FAQ
            </span>
        </div>
        <div className='font-poppins'>
            <Faq
                data={data}
                styles={styles}
                config={config}
            />
        </div>
    </div>
  );
})

export default FAQ