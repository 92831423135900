import React, { forwardRef } from "react";
import { ChevronDownIcon } from '@heroicons/react/outline'

const Home = forwardRef((props, ref) => {
    return (
        <div ref={ref} className="container mx-auto py-80">
            <div className="grid md:grid-cols-1 lg:grid-cols-2 gap-8 items-center" data-aos='fade-down'>
                <div>
                    <span className="block font-bold col-span-1 sm:col-span-2 p-2 font-poppins text-6xl text-transparent bg-clip-text bg-gradient-to-r from-gray-300 to-gray-300">
                        Welcome to your new home in the metaverse
                    </span>
                    <button className='p-12 md:col-span-1 sm:col-span-2 focus:outline-none' onClick={() => {props.scroll(props.refs.about)}}>
                        <ChevronDownIcon className="h-6 items-center animate-bounce text-white" />
                    </button>
                </div>
            </div>
        </div>
    )
})

export default Home;