import React from "react";

function Footer (props) {
    return (
        <div className="container mx-auto">
            <div className="grid sm:grid-cols-1 lg:grid-cols-2 gap-8 items-center py-24">
                <div className='text-left px-6 text-white'>
                    <span className="block font-bold font-poppins text-5xl">
                        Subscribe to our Newsletter!
                    </span>                
                </div>
                <div className='text-left px-2 text-white'>
                    <iframe src="https://metahouses.substack.com/embed" style={{ width: '100%', height: '100%', backgroundColor: 'black', frameborder: '0', scrolling: 'no', borderRadius: '1rem'}}></iframe>  
                </div>
            </div>
        </div>
    )
}

export default Footer;