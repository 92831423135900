import React, { forwardRef } from "react";

const Mint = forwardRef((props, ref) => {
    return (
        <div ref={ref} className="container mx-auto pt-6">
            <div className="grid sm:grid-cols-1 lg:grid-cols-2 gap-8 items-center px-3">
                <div className='items-center justify-center flex text-white pt-12' style={{display: 'inline-block', textAlign: 'center'}}>
                    <img className='w-100' src='icon.png' />
                </div>
                <div className='items-center justify-center text-white text-center'>
                    <span className="block font-bold font-poppins text-6xl text-transparent bg-clip-text bg-gradient-to-r from-blue-700 to-pink-300">
                        Mint
                    </span>
                    <div className='pt-24'>
                        <button className="bg-gray-500 text-white text-2xl font-bold py-4 px-8 rounded opacity-50 cursor-not-allowed">
                                11th Jan
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
})

export default Mint;